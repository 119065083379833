import React, {useEffect, useState} from "react"
import { Link, graphql } from "gatsby"
import ReactPaginate from "react-paginate"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { useInView } from "react-intersection-observer"
import ProductContainer from "../components/productContainer"
import descriptionData from "../content/description.json"
import buildSchema from "../script/buildschema"
import { Helmet } from "react-helmet"

const IndexPage = ({
  data: {
    allAirtable: { edges },
  },
}) => {
  // const data = props.data.allAirtable.edges
  const {
    ref,
    inView: fixHeader,
    entry,
  } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })

  buildSchema(edges)
 

  return (
    <Layout>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.collectivedress.com/party-dresses"
        />
        <title>{`New Dresses | Collective Dress`}</title>
        <meta name="description" content={descriptionData.all.pageDescription}/>        
      </Helmet>

      <div className="container mt-6 px-12 mx-auto max-w-screen-xl">
                          <p className="text-sm">Discover the ideal dress for you using our <Link to="/search" className="font-bold">new search</Link>!!!!!</p>
                        </div>
      
      

      <div id="product-container-parent" className="product-container grid grid-cols-2 onf md:grid-cols-3 max-w-screen-xl mx-auto">
        <ProductContainer edges={edges} path="new" dressType="New" />
      </div>
      <h1 className="text-2xl mt-5 mb-5 font-bold">
          {descriptionData.all.heading}
        </h1>
      <p >
        We aggregate the newest and hottest{" "}
        <Link to={"/maxi-dresses"}>dresses</Link> from different sources, so you
        don't need to spend countless hours browsing different stores and
        boutiques to discover it. Whether you want to wear a{" "}
        <Link to={"/maxi-dresses"}>maxi dress</Link> for a party or a night out
        with the girls, you'll be right on trend in the latest fashion wear.
        You'll find the biggest and best selection of women's dresses at
        Collective dress. Every week, we add and refresh new styles in our dress
        collection, where you'll find{" "}
        <Link to={"/mini-dresses"}>mini dresses</Link>,{" "}
        <Link to={"/midi-dresses"}>midi dresses</Link>, and{" "}
        <Link to={"/party-dresses"}>party dresses</Link>. Also check out our{" "}
        <Link to={"/maxi-dresses"}>maxi dresses</Link> too. We have maxi dresses
        in all styles, like a scoop neck, V-neck, or off shoulder.
      </p>      

      <div className="descriptions-container container px-12 mx-auto max-w-screen-xl">
  
        <p className="text-l mt-5 mb-5 font-bold">
          Collective Dress introduces a variety of new dress styles every Friday. This provides a fresh selection of fashionable items for customers to browse through. Be sure to check in each week for unique updates to your wardrobe.
        </p>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allAirtable(filter: { table: { eq: "dresses_all" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
            product_images_2nd
            product_images_3rd
            product_images_4th
            product_images_5th
            product_images_6th
          }
        }
      }
    }
  }
`

export const Head = () => (
  <Seo
    title={descriptionData.all.pageTitle}
    description={descriptionData.all.pageDescription}
  />
)

export default IndexPage
